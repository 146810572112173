import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
   {/* <h2>Snap!</h2>*/}
   <section className="introSection">
   <div
        style={{
          margin: `0 auto`,
          maxWidth: `960px`,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 50,
        }}
      >
    <div className="titleHolder"><h5 className="sectionTitle">INTRO</h5></div>
    <div className="secHolder">
      <br className="mobileHide" />
  
      <p>Hi 👋, </p>

      <p>I&rsquo;m a Front End Developer from Brighton, UK. I&rsquo;m currently working for a talented design agency whilst undertaking the odd piece of freelance work.</p>
      <p>I have plentiful experience with building modern, responsive websites and web applications for small business to large corporations, and a <span className="italics">ton</span> of email development exposure. At the moment, I am working on bringing myself up to speed with more modern frameworks like React/Gatsby JS.</p>
    </div>



    <div className="titleHolder"><h5 className="sectionTitle">SKILLS</h5></div>
     <div className="secHolder">
      <div className="skillCont">
        <p className="skill">
        <br className="mobileHide" />
        HTML
        CSS/SASS
        Javascript
        PHP
        MySQL
        GIT
        Wordpress
        Ecommerce&nbsp;
        <span className="wsn">Email&nbsp;dev</span></p>
      </div>
    </div>

    <div className="titleHolder"><h5 className="sectionTitle" id="work">WORK</h5></div>
    <div className="secHolder">
      <br className="mobileHide" />
      <h6 className="sectionTitle Sub" id="work">DESIGN AGENCY</h6>

      <div className="workplace"><p>Mint Creative // <span className="smallerText">Developer</span></p></div>
      <p>A range of Front End Development including mobile-first, responsive websites, landing pages, emails (with large subscriber lists), high exposure to Wordpress and e-commerce, and some back end development experience.</p>
   
      <h6 className="sectionTitle Sub" id="work">FREELANCE</h6>
      <div className="workplace"><p>East Beach Cafe // <span className="smallerText">Design &amp; build</span></p></div>
      <p>A fully responsive, simple solution to a previously unresponsive, dated site. From initial design, to deployment, to adhoc changes, followed by the implementation of a online shop. This site was built to last, to be accessible, and to be easily maintable.</p>

    
    <div className="workplace"><p>Projects Abroad // <span className="smallerText">SEO Optimisation</span></p></div>
      <p>Aided the company with SEO optimisation during their migration to a new site. </p>

    



      </div>
    </div>
    </section>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      {/*<Image />*/}
    </div>
   {<Link to="/quiz-page/">Go to page 2</Link>}
  </Layout>
)

export default IndexPage
